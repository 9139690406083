<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.poll"
          label="ID ankety"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-select
          v-model="item.type"
          :items="['ZRKADLA', 'NAZORY', 'TRIBUNAL']"
          label="Typ"
        ></v-select>

      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.first_field"
          label="Prve pole"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.second_field"
          label="Druhe pole"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.name"
          label="Nazov"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.sub_title"
          label="Pod nazov"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        Po kliknutí na otázku sa zobrazí tento text
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <editor v-model="item.description_click"></editor>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        Doplnenie témy
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <editor v-model="item.description"></editor>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        Povedali, napísali niekde inde
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <editor v-model="item.sources"></editor>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto">
        <v-text-field
          v-model="item.order"
          label="Poradie"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          v-model="item.calculation_reversed"
          label="Obrateny vypocet"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-file-input
          v-model="photo_file"
          accept="image/*"
          label="Foto"
          append-outer-icon="mdi-delete"
          @click:append-outer="removePhoto()"
        ></v-file-input>
        <v-img
          v-if="item.photo"
          class="my-5"
          :src="item.photo"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-file-input
          v-model="video_file"
          accept="video/*"
          label="Video"
          append-outer-icon="mdi-delete"
          @click:append="removeVideo()"
        ></v-file-input>
        <div
          v-if="item.video"
          class="text-center"
        >
          <video
            width="500"
            controls
          >
            <source
              :src="item.video"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </v-col>
    </v-row>

    <v-row
      justify="space-between"
      dense
    >
      <v-col align="left">
        <v-switch
          label="Aktivne"
          v-model="item.active"
        ></v-switch>
      </v-col>

      <v-col align="left">
        <v-switch
          label="Diskusia"
          v-model="item.enabled_discussion"
        ></v-switch>
      </v-col>

      <v-col align="right">
        <v-btn
          color="error"
          class="mr-10"
          @click="remove()"
        >Zmazat</v-btn>

        <v-btn
          color="primary"
          @click="save()"
        >Ulozit</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { APIService } from "@/http/APIService.js";
import Editor from "@/components/Editor.vue"

const apiService = new APIService();

export default {
  props: {
    itemID: { type: Number, required: true },
    admin: { type: Boolean, required: false, default: true },
  },


  components: {
    Editor
  },

  data() {
    return {
      item: {},
      photo_file: null,
      video_file: null
    }
  },

  mounted() {
    this.getItem();
  },

  methods: {
    removePhoto() {
      apiService.patchGeneralPollQuestionAdmin({ 'photo': null }, this.item.id).then(response => {
        this.$emit("sync-child");
        this.item = response;
        this.photo_file = null;
      });
    },

    removeVideo() {
      apiService.patchGeneralPollQuestionAdmin({ 'video': null }, this.item.id).then(response => {
        this.$emit("sync-child");
        this.item = response;
        this.video_file = null;
      });
    },

    save() {
      let formData = new FormData();

      for (var key in this.item) {
        if (key == "photo") continue;
        if (key == "video") continue;

        if (this.item[key] != null) formData.set(key, this.item[key]);
      }

      if (this.photo_file instanceof File)
        formData.append("photo", this.photo_file);

      if (this.video_file instanceof File)
        formData.append("video", this.video_file);


      apiService.updateGeneralPollQuestionAdmin(formData, this.item.id).then(response => {
        this.$emit("sync-child");
        this.item = response;
      });
    },

    remove() {
      confirm("Naozaj zmazat?") && apiService.deleteGeneralPollQuestionAdmin(this.item.id).then(() => {
        this.$emit("sync-child");
      });
    },

    getItem() {
      apiService.getGeneralPollQuestionAdmin(this.itemID).then(response => {
        this.item = response;
      });
    },
  }
}
</script>