<template>
  <v-container>
    <v-row dense v-if="admin">
      <v-col>
        <v-text-field
          label="Nazov"
          v-model="item.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          label="Doplnujuci nazov"
          v-model="item.sub_name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="auto">
        <v-select
          label="Typ"
          v-model="item.type"
          :items="['ZRKADLA', 'NAZORY', 'TRIBUNAL']"
        ></v-select>
      </v-col>

      <v-col cols="auto">
        <v-text-field
          v-model="item.order"
          label="Poradie"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-text-field
          v-model="item.icon"
          label="Ikona"
        ></v-text-field>
      </v-col>

      <v-col cols="auto">Ikony hladat <a
          href="https://pictogrammers.com/library/mdi/"
          target="_blank"
        >tu</a> (a potom dat pred najdeny nazov mdi-)</v-col>

    </v-row>

    <v-row dense v-if="admin">
      <v-col>
        O čo nám ide?
      </v-col>
    </v-row>
    <v-row dense v-if="admin">
      <v-col>
        <editor v-model="item.description"></editor>
      </v-col>
    </v-row>
    <v-row dense v-if="admin">
      <v-col>
        Technické informácie
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <editor v-model="item.technical_info"></editor>
      </v-col>
    </v-row>

    <v-row
    v-if="admin"
      justify="space-between"
      dense
    >
      <v-col align="left">
        <v-switch
          label="Aktivne"
          v-model="item.active"
        ></v-switch>
      </v-col>

      <v-col align="left">
        <v-color-picker
          mode="hexa"
          dot-size="25"
          swatches-max-height="200"
          v-model="item.color"
        ></v-color-picker>
      </v-col>

      <v-col align="left">
        <v-switch
          label="Novinka (zobrazi sa prve a vacsie)"
          v-model="item.news"
        ></v-switch>
      </v-col>

      <v-col align="right">
        <v-btn
          v-show="admin"
          color="error"
          class="mr-10"
          @click="remove()"
        >Zmazat</v-btn>

        <v-btn
          color="primary"
          @click="save()"
        >Ulozit</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <general-poll-admins
          v-if="admin"
          :users="item.admins"
          :addAdminFunc="addAdminFunc"
          :removeAdminFunc="removeAdminFunc"
          @sync-child="getItem()"
        >
        </general-poll-admins>
      </v-col>
    </v-row>
  </v-container>
</template>
  <script>
import { APIService } from "@/http/APIService.js";
import Editor from "@/components/Editor.vue";
import GeneralPollAdmins from './GeneralPollAdmins.vue';

const apiService = new APIService();

export default {
  props: {
    itemID: { type: Number, required: true },
    admin: { type: Boolean, required: false, default: true },
  },


  components: {
    GeneralPollAdmins,
    Editor
  },

  data() {
    return {
      item: {}
    }
  },

  mounted() {
    this.getItem();
  },

  methods: {
    addAdminFunc(user_id) {
      return apiService.addAdminGeneralPollCategory(this.itemID, user_id);
    },

    removeAdminFunc(user_id) {
      return apiService.removeAdminGeneralPollCategory(this.itemID, user_id);
    },

    save() {
      apiService.updateGeneralPollCategoryAdmin(this.item).then(response => {
        this.$emit("sync-child");
        this.item = response;
      });
    },

    remove() {
      confirm("Naozaj zmazat?") && apiService.deleteGeneralPollCategoryAdmin(this.item.id).then(() => {
        this.$emit("sync-child");
      });
    },

    getItem() {
      apiService.getGeneralPollCategoryAdmin(this.itemID).then(response => {
        this.item = response;
      });
    },
  }
}
  </script>