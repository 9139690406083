<template>
  <div>
    <v-tabs
      class="mb-5"
      show-arrows
      v-model="tab"
      centered
    >
      <v-tab
        key="default"
        value="default"
      >PZP</v-tab>
      <v-tab>Nastavenia</v-tab>
      <v-tab>Používatelia</v-tab>
      <v-tab>Pohyby na účte</v-tab>
      <v-tab>Kandidatúra</v-tab>
      <v-tab>Členovia NH</v-tab>
      <v-tab>Exekutíva NH</v-tab>
      <v-tab>Menu</v-tab>
      <v-tab>Stránky</v-tab>
      <v-tab>O našom hnutí</v-tab>
      <v-tab>Súbory</v-tab>
      <v-tab
        key="new_questions"
        value="new_questions"
      >Referendá</v-tab>
      <v-tab>Prieskumy</v-tab>
      <v-tab>Ankety</v-tab>

      <v-tab-item key="default">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="auto"
            class="text-h1 font-weight-bold"
          >
            <span class="green--text">{{ statistics.all_users_count }}</span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-h2 font-weight-bold"
        >
          <v-col cols="auto">
            <span class="red--text">{{ statistics.not_verified_bank_users_count }}</span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-h4"
        >
          <v-col cols="auto">
            <span class="red--text">({{ statistics.all_users_count - statistics.not_verified_bank_users_count }})</span>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="center"
          class="text-h3 font-weight-bold"
        >
          <v-col cols="auto">
            <span class="blue--text">{{ statistics.not_verified_sms_users_count }}</span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-h5"
        >
          <v-col cols="auto">
            <span class="blue--text">({{ statistics.all_users_count - statistics.not_verified_sms_users_count }})</span>
          </v-col>
        </v-row>

        <v-row
          align="center"
          justify="center"
          class="text-h4 font-weight-bold"
        >
          <v-col cols="auto">
            <span class="">{{ statistics.not_verified_email_users_count }}</span>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          class="text-h6"
        >
          <v-col cols="auto">
            <span class="">({{ statistics.all_users_count - statistics.not_verified_email_users_count }})</span>
          </v-col>
        </v-row>

      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col
            cols="3"
            v-for="setting in settings.results"
            :key="setting.name"
          >
            <v-text-field
              v-model="setting.value"
              :label="setting.name"
            ></v-text-field>
            <v-btn @click="saveSetting(setting)">Uložiť</v-btn>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col>
            <data-table-paging-filtering
              :headers="headers_users"
              :get-items-func="getItemsUsers"
              :item-row-background-func="itemRowBackgroundFuncUsers"
              :show-expand="true"
              :dense="true"
              :sync="sync_users"
              uniqueID="admin-users"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <user-admin-info :user="item"></user-admin-info>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        label="Overenie administratorom na uroven 0"
                        v-model="item.verified_by_superuser_uroven_0"
                      ></v-checkbox>

                      <v-checkbox
                        label="Overenie administratorom na uroven 1"
                        v-model="item.verified_by_superuser_uroven_1"
                      ></v-checkbox>
                      <v-checkbox
                        label="Overenie administratorom na uroven 2"
                        v-model="item.verified_by_superuser_uroven_2"
                      ></v-checkbox>
                      <v-checkbox
                        label="Overenie administratorom na uroven 3"
                        v-model="item.verified_by_superuser_uroven_3"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        label="Overenie administratorom na uroven 4"
                        v-model="item.verified_by_superuser_uroven_4"
                      ></v-checkbox>
                      <v-checkbox
                        label="Overenie administratorom na uroven vsetko"
                        v-model="item.verified_by_superuser"
                      ></v-checkbox>
                      <v-btn
                        color="primary"
                        @click="saveUserPermissions(item)"
                      >Ulozit opravnenia</v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="pa-5">
                    <v-col>
                      <v-btn @click="resendUserSMS(item)">Znovu poslat verifikacnu SMS</v-btn>
                    </v-col>
                    <v-col>
                      <v-btn @click="resendUserEmail(item)">Znovu poslat verifikacny EMAIL</v-btn>
                    </v-col>
                  </v-row>

                </td>
              </template>

              <template v-slot:item.verified="{ item }">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="deleteUser(item)"
                >
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment("YYYY-MM-DD HH:mm") }}
              </template>

            </data-table-paging-filtering>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="downloadItem('/adminuser_export/', 'export_users.xlsx')">EXPORT</v-btn>
          </v-col>
          <v-col>
            <v-btn @click="downloadItem('/adminuser_export_simple/', 'export_users_simple.xlsx')">EXPORT KRATKY</v-btn>
          </v-col>

        </v-row>
      </v-tab-item>

      <v-tab-item>
        <data-table-paging-filtering
          :headers="headers_bank_transfers"
          :get-items-func="getItemsBankTransfers"
          :item-row-background-func="itemRowBackgroundTransfersFunc"
          :show-expand="true"
          :dense="true"
          :sync="sync_bank_transfers"
          uniqueID="admin-bank-transfers"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <user-admin-info :user="item"></user-admin-info>
            </td>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("YYYY-MM-DD HH:mm") }}
          </template>

          <template v-slot:item.raw="{ item }">
            <v-icon
              color="primary"
              @click="editPayment(item)"
            >
              mdi-account-edit
            </v-icon>
          </template>

        </data-table-paging-filtering>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col>
            <data-table-paging-filtering
              :headers="headers_candidates"
              :get-items-func="getItemsCandidates"
              :item-row-background-func="itemRowBackgroundFunc"
              :show-expand="true"
              :dense="true"
              :sync="sync_candidates"
              :searchEnabled="true"
              uniqueID="admin-candidates"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card>
                    <v-card-title class="justify-center">
                      {{ item.name }}</v-card-title>
                    <v-card-text class="text-center">
                      <div>
                        <img
                          width="300"
                          :src="item.photo"
                        />
                      </div>
                      <v-row
                        align="center"
                        class="mx-0"
                      > </v-row>

                      <div class="my-4 subtitle-1">
                        {{ item.location }}
                      </div>

                      <div>{{ item.short_info }}</div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-title
                      class="justify-center"
                      to
                    >Informácie</v-card-title>

                    <v-card-text class="text-center">
                      <div>
                        <video
                          width="300"
                          controls
                        >
                          <source
                            :src="item.video"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      {{ item.long_info }}
                    </v-card-text>
                    <v-card-actions> </v-card-actions>
                  </v-card>

                  <user-admin-info :user="item.user"></user-admin-info>
                </td>
              </template>

              <template v-slot:item.approved="{ item }">
                <v-icon
                  v-if="!item.approved"
                  class="mr-2"
                  color="success"
                  @click="approveCandidate(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  class="mr-2"
                  color="error"
                  @click="disapproveCandidate(item)"
                >
                  mdi-close
                </v-icon>
                <v-icon
                  class="ma-2"
                  color="error"
                  @click="deleteCandidate(item)"
                >
                  mdi-karate
                </v-icon>
              </template>
            </data-table-paging-filtering>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <data-table-paging-filtering
              :headers="headers_members"
              :get-items-func="getItemsMembers"
              :item-row-background-func="itemRowBackgroundFunc"
              :show-expand="true"
              :dense="true"
              :sync="sync_members"
              uniqueID="admin-members"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-card>
                    <v-card-title>Zadané údaje v žiadosti</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.first_name"
                            disabled
                            label="Meno"
                            name="first_name"
                            prepend-icon="mdi-account"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.first_name_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.last_name"
                            disabled
                            label="Priezvisko"
                            name="last_name"
                            prepend-icon="mdi-account"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.user.last_name_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.address_town"
                            disabled
                            label="Mesto/Obec"
                            name="address_town"
                            prepend-icon="mdi-map-marker"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.address_town_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>

                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.address_zip_code"
                            disabled
                            label="PSČ"
                            name="address_zip_code"
                            prepend-icon="mdi-map-marker"
                            type="text"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.address_okres"
                            disabled
                            label="Okres"
                            name="address_okres"
                            prepend-icon="mdi-map-marker"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.address_okres_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>

                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.address_kraj"
                            disabled
                            label="Kraj"
                            name="address_kraj"
                            prepend-icon="mdi-map-marker"
                            type="text"
                          />
                        </v-col>
                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.address_kraj_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>

                      </v-row>
                      <v-row>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-text-field
                            v-model="item.user.address_country"
                            disabled
                            label="Štát"
                            name="address_country"
                            prepend-icon="mdi-flag"
                            type="text"
                          />
                        </v-col>

                        <v-col
                          md="3"
                          sm="4"
                        >
                          <v-switch
                            v-model="item.address_country_public"
                            disabled
                            label="Zverejnené"
                          ></v-switch>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          md="3"
                          sm="8"
                        >
                          <v-select
                            v-model="item.payment"
                            label="Platba"
                            name="payment"
                            prepend-icon="mdi-cash"
                            :items="selects"
                          />
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>

                  <user-admin-info :user="item.user"></user-admin-info>
                </td>
              </template>

              <template v-slot:item.approved="{ item }">
                <v-icon
                  v-if="!item.approved"
                  class="mr-2"
                  color="success"
                  @click="approveMember(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  class="mr-2"
                  color="error"
                  @click="disapproveMember(item)"
                >
                  mdi-close
                </v-icon>
              </template>
            </data-table-paging-filtering>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <data-table-paging-filtering
              :headers="headers_staff"
              :get-items-func="getItemsStaff"
              :item-row-background-func="itemRowBackgroundFunc"
              :show-expand="true"
              :dense="true"
              :sync="sync_staff"
              uniqueID="'admin-staff'"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <user-admin-info :user="item.user"></user-admin-info>
                </td>
              </template>

              <template v-slot:item.approved="{ item }">
                <v-icon
                  v-if="!item.approved"
                  class="mr-2"
                  color="success"
                  @click="approveStaff(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else
                  class="mr-2"
                  color="error"
                  @click="disapproveStaff(item)"
                >
                  mdi-close
                </v-icon>
              </template>
            </data-table-paging-filtering>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <admin-menu></admin-menu>
      </v-tab-item>
      <v-tab-item>
        <admin-pages></admin-pages>
      </v-tab-item>

      <v-tab-item>
        <template v-for="page in staticPages.results">
          <div
            v-bind:key="page.id"
            class="my-10"
          >
            <v-row>
              <v-col>
                <v-card class="text-center">
                  <v-card-title>
                    <v-text-field v-model="page.title"> </v-text-field>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-text-field v-model="page.subtitle"> </v-text-field>
                  </v-card-subtitle>
                  <v-card-text>
                    <Editor v-model="page.content" />
                  </v-card-text>
                  <v-card-actions></v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="updateStaticPage(page)">Uložiť</v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col>
            <data-table-paging-filtering
              :headers="headers_files"
              :get-items-func="getItemsFiles"
              :item-row-background-func="itemRowBackgroundFunc"
              :show-expand="false"
              :dense="true"
              :sync="sync_files"
              uniqueID="admin-files"
            >
              <template v-slot:item.id="{ item }">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="deleteFile(item)"
                >
                  mdi-trash-can
                </v-icon>
              </template>

              <template v-slot:item.file="{ item }"> <a
                  v-if="item.file.endsWith('.pdf')"
                  :href="item.file"
                  target="_blank"
                >
                  https://docs.google.com/gview?embedded=true&url={{ item.file }} </a>
                <a
                  v-else
                  :href="item.file"
                  target="_blank"
                >
                  {{ item.file }} </a>
              </template>
            </data-table-paging-filtering>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="ma-1">
              <v-card-title>Nový súbor</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="new_file.name"
                  label="Názov"
                ></v-text-field>
                <v-file-input
                  color="primary"
                  v-model="new_file.file"
                  label="Súbor"
                ></v-file-input>
                <v-btn @click="uploadFile()">Nahrať</v-btn>
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>Všetky výbery otázok</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      Všetky výbery otázok
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <data-table-paging-filtering
                            :headers="headers_question_choose"
                            :get-items-func="getItemsQuestionChoosesAdmin"
                            :item-row-background-func="itemRowBackgroundFunc"
                            :show-expand="true"
                            :dense="true"
                            :sync="sync_question_choose"
                            uniqueID="admin-referendum-question-choose"
                          >
                            <template v-slot:item.start_date="{ item }">
                              <div style="white-space: pre;">
                                {{ item.start_date | moment("DD.MM.YYYY[\n] HH:mm:ss") }}
                              </div>
                            </template>

                            <template v-slot:item.end_date="{ item }">
                              <div style="white-space: pre;">
                                {{ item.end_date | moment("DD.MM.YYYY[\n] HH:mm:ss") }}
                              </div>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <draggable v-model="item.question_choose_questions">
                                  <v-card
                                    v-for="question in item.question_choose_questions"
                                    v-bind:key="question.id"
                                    class="ma-1"
                                    :color="getQuestionColor(question)"
                                  >
                                    <v-card-title>ID: {{ question.id }}</v-card-title>
                                    <v-card-text>{{ question.text }}</v-card-text>
                                    <v-card-text>Navrhovateľ: {{ question.user_first_name }},
                                      {{ question.user_location }}</v-card-text>
                                    <v-card-actions><v-btn @click="removeQuestionFromQuestionChoose(question.id)">Odstranit</v-btn></v-card-actions>
                                  </v-card>
                                </draggable>

                                <v-card class="ma-1">
                                  <v-card-title>Pridat otazku do vyberu otazok</v-card-title>
                                  <v-card-text>
                                    <v-text-field
                                      label="ID otazky"
                                      v-model="item.new_choose_id"
                                    ></v-text-field>
                                    <v-btn @click="addQuestionToQuestionChoose(item.new_choose_id, item.id)">Pridat</v-btn>
                                  </v-card-text>
                                </v-card>

                                <v-card class="ma-1">
                                  <v-card-title>Nastavenia</v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="4">
                                        <v-datetime-picker
                                          label="Zaciatok"
                                          v-model="item.start_date"
                                        >
                                        </v-datetime-picker>
                                        <!-- <v-text-field
                                    v-model="item.start_date"
                                    type="datetime"
                                    label="Zaciatok"
                                  ></v-text-field> -->
                                      </v-col>
                                      <v-col cols="4">

                                        <v-datetime-picker
                                          label="Koniec"
                                          v-model="item.end_date"
                                        > </v-datetime-picker>

                                        <!-- <v-text-field
                                    v-model="item.end_date"
                                    type="datetime"
                                    label="Koniec"
                                  ></v-text-field> -->
                                      </v-col>
                                    </v-row>

                                    <v-row>
                                      <v-col cols="12">
                                        <v-text-field
                                          v-model="item.name"
                                          label="Nazov"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-textarea
                                          v-model="item.tooltip"
                                          label="Tooltip"
                                          auto-grow
                                          rows="1"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>

                                    <v-row>
                                      <v-col cols="4">
                                        <v-checkbox
                                          v-model="item.active_force"
                                          label="Aktivacia administratorom"
                                        ></v-checkbox>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-checkbox
                                          v-model="item.closed"
                                          label="Uzavretie administratorom"
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="saveQuestionChooseItem(item)">Ulozit</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </td>
                            </template>
                          </data-table-paging-filtering>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="newQuestionChoose">Nový výber otázok</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="new_questions">
            <v-expansion-panel-header>Všetky referendá</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      Všetky referendá
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <data-table-paging-filtering
                            :headers="headers_referendum"
                            :get-items-func="getItemsReferendumsAdmin"
                            :item-row-background-func="itemRowBackgroundFunc"
                            :show-expand="true"
                            :dense="true"
                            :sync="sync_referendum"
                            uniqueID="admin-referendum-referendum"
                          >

                            <template v-slot:item.start_date="{ item }">
                              <div style="white-space: pre;">
                                {{ item.start_date | moment("DD.MM.YYYY[\n] HH:mm:ss") }}
                              </div>
                            </template>

                            <template v-slot:item.end_date="{ item }">
                              <div style="white-space: pre;">
                                {{ item.end_date | moment("DD.MM.YYYY[\n] HH:mm:ss") }}
                              </div>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <draggable v-model="item.referendum_questions">
                                  <v-card
                                    v-for="question in item.referendum_questions"
                                    v-bind:key="question.id"
                                    class="ma-1"
                                    :color="getQuestionColor(question)"
                                  >
                                    <v-card-title>ID: {{ question.id }}</v-card-title>
                                    <v-card-text>{{ question.text }}</v-card-text>
                                    <v-card-text>Navrhovateľ: {{ question.user_first_name }},
                                      {{ question.user_location }}</v-card-text>

                                    <v-card-text>
                                      <v-checkbox
                                        v-model="question.enabled_discussion"
                                        label="Dikusia povolena"
                                      ></v-checkbox>
                                    </v-card-text>

                                    <v-card-actions><v-btn @click="removeQuestionFromReferendum(question.id)">Odstranit</v-btn> <v-btn @click="saveQuestionText(question)">Ulozit</v-btn></v-card-actions>
                                  </v-card>
                                </draggable>

                                <v-card class="ma-1">
                                  <v-card-title>Pridat otazku do referenda</v-card-title>
                                  <v-card-text>
                                    <v-text-field
                                      label="ID otazky"
                                      v-model="item.new_choose_id"
                                    ></v-text-field>
                                    <v-btn @click="addQuestionToReferendum(item.new_choose_id, item.id)">Pridat</v-btn>
                                  </v-card-text>
                                </v-card>

                                <v-card class="ma-1">
                                  <v-card-title>Nastavenia</v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="4">
                                        <v-datetime-picker
                                          label="Zaciatok"
                                          v-model="item.start_date"
                                        >
                                        </v-datetime-picker>
                                        <!-- <v-text-field
                                    v-model="item.start_date"
                                    type="datetime"
                                    label="Zaciatok"
                                  ></v-text-field> -->
                                      </v-col>
                                      <v-col cols="4">

                                        <v-datetime-picker
                                          label="Koniec"
                                          v-model="item.end_date"
                                        > </v-datetime-picker>

                                        <!-- <v-text-field
                                    v-model="item.end_date"
                                    type="datetime"
                                    label="Koniec"
                                  ></v-text-field> -->
                                      </v-col>
                                    </v-row>

                                    <v-row>
                                      <v-col cols="12">
                                        <v-text-field
                                          v-model="item.name"
                                          label="Nazov"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12">
                                        <v-textarea
                                          v-model="item.tooltip"
                                          label="Tooltip"
                                          auto-grow
                                          rows="1"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>

                                    <v-row>
                                      <v-col cols="4">
                                        <v-checkbox
                                          v-model="item.active_force"
                                          label="Aktivacia administratorom"
                                        ></v-checkbox>
                                      </v-col>
                                      <v-col cols="4">
                                        <v-checkbox
                                          v-model="item.closed"
                                          label="Uzavretie administratorom"
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="saveReferendumItem(item)">Ulozit</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </td>
                            </template>
                          </data-table-paging-filtering>
                        </v-col>
                      </v-row>

                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="newReferendum">Nové referendum</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Otazky do Nasho Referenda od pouzivatelov</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      Otazky do Nasho Referenda od pouzivatelov
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <data-table-paging-filtering
                            :headers="headers_referendum_question"
                            :get-items-func="getItemsReferendumQuestionsAdmin"
                            :item-row-background-func="itemRowBackgroundFunc"
                            :show-expand="true"
                            :dense="true"
                            :sync="sync_referendum_questions"
                            uniqueID="admin-referendum-questions"
                          >
                            <template v-slot:item.created_at="{ item }">
                              <div style="white-space: pre;">
                                {{ item.created_at | moment("DD.MM.YYYY[\n] HH:mm:ss") }}
                              </div>
                            </template>

                            <template v-slot:item.status="{ item }">
                              <v-chip :color="getStatus(item.status)[1]">
                                {{ getStatus(item.status)[0] }}
                              </v-chip>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <v-card>
                                  <v-card-title>ID: {{ item.id }}</v-card-title>
                                  <v-card-text><v-textarea
                                      v-model="item.text"
                                      label="Otazka"
                                      auto-grow
                                      rows="1"
                                    ></v-textarea></v-card-text>
                                  <v-card-text><v-textarea
                                      v-model="item.tooltip"
                                      label="Tooltip"
                                      auto-grow
                                      rows="1"
                                    ></v-textarea></v-card-text>
                                  <v-card-text>Navrhovateľ: {{ item.user_first_name }},
                                    {{ item.user_location }}</v-card-text>
                                  <v-card-actions>
                                    <v-btn @click="disapproveQuestion(item)">Zamietnut</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="approveQuestion(item)">Schvalit</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="saveQuestionText(item)">Ulozit</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </td>
                            </template>
                          </data-table-paging-filtering>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                Všetky prieskumy
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <data-table-paging-filtering
                      :headers="headers_survey"
                      :get-items-func="getItemsSurveysAdmin"
                      :item-row-background-func="itemRowBackgroundFunc"
                      :show-expand="true"
                      :dense="true"
                      :sync="sync_survey"
                      uniqueID="admin-survey"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <!-- <draggable v-model="item.parties"> -->
                          <v-card
                            v-for="party in item.parties"
                            v-bind:key="party.id"
                            class="ma-1"
                          >
                            <v-card-title>ID: {{ party.id }}</v-card-title>
                            <v-card-text><v-row><v-col>{{ party.name }}</v-col><v-col> <v-img
                                    max-height="50"
                                    max-width="50"
                                    :src="party.logo"
                                  ></v-img></v-col><v-col><v-btn
                                    dark
                                    :color="party.color"
                                  >FARBA</v-btn></v-col></v-row></v-card-text>
                            <v-card-actions><v-btn @click="removePartyFromSurvey(item.id, party.id)">Odstranit</v-btn></v-card-actions>
                          </v-card>
                          <!-- </draggable> -->

                          <v-card class="ma-1">
                            <v-card-title>Pridat polozku do prieskumu</v-card-title>
                            <v-card-text>
                              <v-text-field
                                label="ID strany"
                                v-model="item.party_id"
                              ></v-text-field>
                              <v-btn @click="addPartyToSurvey(item.id, item.party_id)">Pridat</v-btn>
                            </v-card-text>
                          </v-card>

                          <v-card class="ma-1">
                            <v-card-title>Nastavenia</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="auto">
                                  <v-text-field
                                    v-model="item.name"
                                    label="Nazov"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="auto">
                                  <v-text-field
                                    v-model="item.sub_name"
                                    label="Pod nazov"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="2">
                                  <v-select
                                    v-model="item.type"
                                    label="Typ"
                                    :items="['STRANA', 'CLOVEK', 'INE']"
                                  ></v-select>
                                </v-col>

                                <v-col cols="4">
                                  <v-text-field
                                    v-model="item.total_possible_everyone"
                                    label="Pocet opravnenych volicov v krajine"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox
                                    v-model="item.active"
                                    label="Aktivne"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox
                                    v-model="item.enabled_voting"
                                    label="Hlasovanie povolene"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox
                                    v-model="item.enabled_discussion"
                                    label="Diskusia povolena"
                                  ></v-checkbox>
                                </v-col>

                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn @click="saveSurvey(item)">Ulozit</v-btn>
                            </v-card-actions>
                          </v-card>
                        </td>
                      </template>
                    </data-table-paging-filtering>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="newSurvey()">Nový prieskum</v-btn>
              </v-card-actions>
            </v-card>

            <v-card>
              <v-card-title>
                Všetky položky
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <data-table-paging-filtering
                      :headers="headers_survey_parties"
                      :get-items-func="getItemsSurveyPartiesAdmin"
                      :item-row-background-func="itemRowBackgroundFunc"
                      :show-expand="true"
                      :dense="true"
                      :sync="sync_survey_parties"
                      uniqueID="admin-survey"
                    >
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-card class="ma-1">
                            <v-card-title>Nastavenia</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="item.name"
                                    label="Nazov"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12">
                                  <v-select
                                    v-model="item.type"
                                    label="Typ"
                                    :items="['STRANA', 'CLOVEK', 'INE']"
                                  ></v-select>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="item.logo"
                                    label="Logo (url)"
                                  ></v-text-field>
                                  <v-img
                                    max-height="50"
                                    max-width="50"
                                    :src="item.logo"
                                  ></v-img>
                                </v-col>
                                <v-col cols="12">
                                  <v-color-picker
                                    mode="hexa"
                                    dot-size="25"
                                    swatches-max-height="200"
                                    v-model="item.color"
                                  ></v-color-picker> </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn @click="saveSurveyParty(item)">Ulozit</v-btn>
                            </v-card-actions>
                          </v-card>
                        </td>
                      </template>
                    </data-table-paging-filtering>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="newSurveyParty()">Nová položka</v-btn>
              </v-card-actions>
            </v-card>

          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <admin-general-poll></admin-general-poll>
      </v-tab-item>
    </v-tabs>

    <v-dialog
      v-model="dialog_payment"
      width="800px"
    >
      <v-card>
        <v-toolbar
          dark
          color="indigo"
        >
          <v-btn
            icon
            dark
            @click="dialog_payment = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="flex-grow-1" />
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-autocomplete
              v-model="dialog_payment_item_user"
              item-text="username"
              item-value="id"
              placeholder="Používateľ"
              label="Používateľ"
              :items="dialog_payment_user_items"
              auto-select-first
              :search-input.sync="dialog_payment_user_sync"
              cache-items
              hide-no-data
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="updatePayment()"
            >Uložiť</v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { APIService } from "@/http/APIService.js";

import adminMenu from "./admin/Menu.vue";
import adminPages from "./admin/Pages.vue";
import adminGeneralPoll from "./admin/GeneralPoll/GeneralPoll.vue";

import DataTablePagingFiltering from "@/components/DataTablePagingFiltering.vue";
import UserAdminInfo from "@/components/UserAdminInfo.vue";

import Editor from "@/components/Editor.vue"
import draggable from "vuedraggable";


Vue.use(require("vue-moment"));

const apiService = new APIService();

export default {
  name: "Admin",
  components: {
    adminMenu,
    adminPages,
    DataTablePagingFiltering,
    UserAdminInfo,
    Editor,
    draggable,
    adminGeneralPoll
  },

  data() {
    return {
      panel: 2,
      statistics: {},

      dialog_payment: false,
      dialog_payment_item:
      {
        user_assigned_to: {
          id: null
        }
      },
      dialog_payment_user_items: [],
      dialog_payment_user_sync: null,
      dialog_payment_item_user: null,

      settings: [],
      tab: "default",

      expanded: [],

      items_users: {},
      items: {},
      items_members: {},
      items_staff: {},

      items_referendum: {},
      items_question_choose: {},
      items_referendum_question: {},
      items_surveys: {},
      items_survey_parties: {},

      sync_users: false,
      sync_candidates: false,
      sync_members: false,
      sync_staff: false,
      sync_bank_transfers: false,
      sync_files: false,
      sync_referendum: false,
      sync_question_choose: false,
      sync_referendum_questions: false,
      sync_survey: false,
      sync_survey_parties: false,

      staticPages: [],

      new_file: {
        name: "",
        file: null
      },

      users_count: null,


      headers_bank_transfers: [
        { text: "ID", value: "id" },
        { text: "VS", value: "variabilny_symbol" },

        { text: "Prijatie platby v IS", value: "created_at" },
        { text: "Z účtu", value: "account_from" },

        { text: "Na účet", value: "account_to" },
        { text: "Prijatie platby v banke", value: "date" },
        {
          text: "Username",
          value: "user_assigned_to.username",
          model: "user__username",
        },

        { text: "Suma", value: "amount" },

        { text: "Mena", value: "currency" },
        {
          text: "Prispel vlastny ucet",
          value: "own",
        },
        {
          text: "Akcie",
          value: "raw",
          sortable: false,
        },
      ],

      headers_users: [
        { text: "VS", value: "id" },
        { text: "Username", value: "username" },

        { text: "Email", value: "email" },
        { text: "Telefón", value: "phone_number" },
        { text: "Meno", value: "first_name", model: "user__first_name" },

        { text: "Priezvisko", value: "last_name", model: "user__last_name" },
        { text: "IBAN", value: "bank_account" },
        { text: "Registrácia", value: "created_at" },
        { text: "Email", value: "verified_email"},
        { text: "SMS", value: "verified_sms" },
        { text: "Email a SMS", value: "verified_sms_email_" },


        { text: "Prispel celkovo", value: "bank_transfers_total" },
        {
          text: "Prispel vlastny ucet",
          value: "bank_transfers_total_own",
        },

        { text: "Akcie", value: "verified", sortable: false },
      ],

      headers_candidates: [
        { text: "VS", value: "user.id" },

        { text: "ID", value: "id" },
        { text: "Poradie", value: "rank" },
        {
          text: "Priezvisko",
          value: "user.last_name",
          model: "user__last_name",
        },
        { text: "Meno", value: "user.first_name", model: "user__first_name" },
        {
          text: "Okres",
          value: "user.address_okres",
          model: "user__address_okres",
        },
        {
          text: "Mesto/Obec",
          value: "user.address_town",
          model: "user__address_town",
        },
        { text: "Telefón", value: "user.phone_number" },
        { text: "Email", value: "user.email" },
        { text: "Prispel celkovo", value: "bank_transfers_total" },
        {
          text: "Prispel vlastny ucet",
          value: "bank_transfers_total_own",
        },
        { text: "Počet hlasov", value: "votes" },
        { text: "Akcie", value: "approved" },
      ],

      headers_members: [
        { text: "VS", value: "user.id" },

        { text: "ID", value: "id" },

        { text: "Email", value: "user.email" },
        { text: "Telefón", value: "user.phone_number" },
        { text: "Meno", value: "user.first_name", model: "user__first_name" },
        {
          text: "Priezvisko",
          value: "user.last_name",
          model: "user__last_name",
        },
        { text: "Prispel celkovo", value: "bank_transfers_total" },
        {
          text: "Prispel vlastny ucet",
          value: "bank_transfers_total_own",
        },
        {
          text: "Status",
          value: "status"
        },
        { text: "Akcie", value: "approved" },
      ],

      headers_staff: [
        { text: "VS", value: "user.id" },

        { text: "ID", value: "id" },

        { text: "Email", value: "user.email" },
        { text: "Telefón", value: "user.phone_number" },
        { text: "Meno", value: "user.first_name", model: "user__first_name" },
        {
          text: "Priezvisko",
          value: "user.last_name",
          model: "user__last_name",
        },
        { text: "Prispel celkovo", value: "bank_transfers_total" },
        {
          text: "Prispel vlastny ucet",
          value: "bank_transfers_total_own",
        },
        { text: "Akcie", value: "approved" },
      ],

      headers_files: [
        { text: "Názov", value: "name" },
        { text: "URL", value: "file" },
        { text: "Akcie", value: "id" },
      ],

      headers_referendum: [
        { text: "ID", value: "id" },
        { text: "Zaciatok", value: "start_date" },
        { text: "Koniec", value: "end_date" },

        { text: "Uzavrete", value: "closed" },
        { text: "Aktivne", value: "active" },


        { text: "Meno", value: "name", width: "30%" },
      ],

      headers_question_choose: [
        { text: "ID", value: "id" },
        { text: "Zaciatok", value: "start_date" },
        { text: "Koniec", value: "end_date" },

        { text: "Uzavrete", value: "closed" },
        { text: "Aktivne", value: "active" },


        { text: "Meno", value: "name", width: "30%" },
      ],

      headers_referendum_question: [
        { text: "ID", value: "id" },

        { text: "Vytvorene", value: "created_at" },
        { text: "Pouzivatelske ID", value: "user" },
        { text: "Vyber otazok ID", value: "question_choose" },
        { text: "Referendum ID", value: "referendum" },
        { text: "Status", value: "status", width: "15%" },
      ],

      headers_survey: [
        { text: "ID", value: "id" },

        { text: "Nazov", value: "name" },
        { text: "Typ", value: "type" },

        { text: "Aktivne", value: "active" },
      ],

      headers_survey_parties: [
        { text: "ID", value: "id" },
        { text: "Nazov", value: "name" },
        { text: "Typ", value: "type" },
      ],


      selects: [
        { text: "Ročne", value: "YEARLY" },
        { text: "Mesačne", value: "MONTHLY" },
      ]


    };
  },

  mounted: function () {
    this.getAdminSettings();
    this.getStatistics();
    this.getStaticPages();
    this.getItemsUsers('', '', '', '', '').then((response) => {
      this.users_count = response.count;
    });
    this.tab = this.getTab;
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated", "username"]),
    getTab() {
      return this.$route.params.tabID;
    }
  },

  watch: {
    dialog_payment_user_sync(val) {
      val && this.querySelectionsUsers(val);
    },
  },

  methods: {
    getStatus(status) {
      switch (status) {
        case 'NEW': return ["Nová", "grey"]
        case 'APPROVED': return ["Schválená", "success"]
        case 'REJECTED': return ["Neschválená", "error"]
        default: return ["Žiadny", "warning"]
      }
    },

    getQuestionColor(question) {
      if (question.status != 'APPROVED') {
        return "red";
      }
    },
    querySelectionsUsers(v) {

      apiService.searchUsers(v).then((response) => {
        this.dialog_payment_user_items = response.results;
      });
    },

    itemRowBackgroundFunc() {
      return "";
    },

    itemRowBackgroundFuncUsers(item) {
      if (!item.verified_sms_email_)
        return "red lighten-3";
      else if (!item.verified_bank && item.bank_account) {
        return "light-blue lighten-4";
      }
      return "";
    },

    itemRowBackgroundTransfersFunc(item) {
      if (item.amount < 0)
        return "";

      if (item.user_assigned_to == null) {
        return "red lighten-3"
      }

      if (item.inially_unidentified) {
        return "yellow lighten-3"
      }

      return "";
    },

    saveSetting(item) {
      apiService.updateAdminSetting(item);
    },

    getStatistics() {
      apiService.getStatistics().then((response) => {
        this.statistics = response;
      });
    },

    getAdminSettings() {
      apiService.getAdminSettings().then((response) => {
        this.settings = response;
      });
    },

    async approveCandidate(item) {
      confirm("Naozaj?") &&
        apiService.approveAdminKandidat(item).then(() => {
          this.sync_candidates = !this.sync_candidates;
        });
    },

    async disapproveCandidate(item) {
      confirm("Naozaj?") &&
        apiService.disapproveAdminKandidat(item).then(() => {
          this.sync_candidates = !this.sync_candidates;
        });
    },

    async deleteCandidate(item) {
      confirm("Naozaj?") &&
        apiService.deleteAdminKandidat(item).then(() => {
          this.sync_candidates = !this.sync_candidates;
        });
    },

    getItemsUsers(sort, search, limit, offset, filters) {
      return apiService.getAdminUsers(sort, search, limit, offset, filters);
    },

    async approveUser(item) {
      confirm("Naozaj?") &&
        apiService.adminVerifyUser(item).then(() => {
          this.sync_users = !this.sync_users;
        });
    },

    async deleteUser(item) {
      confirm("Naozaj?") &&
        apiService.adminDeleteUser(item).then(() => {
          this.sync_users = !this.sync_users;
        });
    },


    getItemsCandidates(sort, search, limit, offset, filters) {
      return apiService.getAdminKandidats(sort, search, limit, offset, filters);
    },

    getItemsMembers(sort, search, limit, offset, filters) {
      return apiService.getMembers(sort, search, limit, offset, filters);
    },

    getItemsBankTransfers(sort, search, limit, offset, filters) {
      return apiService.getBankTransfers(sort, search, limit, offset, filters);
    },

    getItemsFiles(sort, search, limit, offset, filters) {
      return apiService.getFiles(sort, search, limit, offset, filters);
    },

    getItemsReferendumsAdmin(sort, search, limit, offset, filters) {
      return apiService.getReferendumsAdmin(sort, search, limit, offset, filters);
    },

    getItemsQuestionChoosesAdmin(sort, search, limit, offset, filters) {
      return apiService.getQuestionChoosesAdmin(sort, search, limit, offset, filters);
    },

    getItemsReferendumQuestionsAdmin(sort, search, limit, offset, filters) {
      return apiService.getReferendumQuestionsAdmin(sort, search, limit, offset, filters);
    },

    getItemsSurveysAdmin(sort, search, limit, offset, filters) {
      return apiService.getSurveysAdmin(sort, search, limit, offset, filters);
    },

    getItemsSurveyPartiesAdmin(sort, search, limit, offset, filters) {
      return apiService.getSurveyPartiesAdmin(sort, search, limit, offset, filters);
    },




    uploadFile() {
      let formData = new FormData();

      formData.append("file", this.new_file.file, this.new_file.file.name);

      formData.append("name", this.new_file.name);

      return apiService.createFile(formData).then(() => {
        this.new_file.file = null;
        this.new_file.name = "";
        this.sync_files = !this.sync_files;
      });
    },

    async deleteFile(item) {
      confirm("Naozaj?") &&
        apiService.deleteFile(item).then((response) => {
          this.sync_files = !this.sync_files;
        });
    },


    async approveMember(item) {
      confirm("Naozaj?") &&
        apiService.approveMember(item).then((response) => {
          this.sync_members = !this.sync_members;
        });
    },

    async disapproveMember(item) {
      confirm("Naozaj?") &&
        apiService.disapproveMember(item).then((response) => {
          this.sync_members = !this.sync_members;
        });
    },

    getItemsStaff(sort, search, limit, offset, filters) {
      return apiService.getStaffs(sort, search, limit, offset, filters);
    },

    async approveStaff(item) {
      confirm("Naozaj?") &&
        apiService.approveStaff(item).then((response) => {
          this.sync_staff = !this.sync_staff;
        });
    },

    async disapproveStaff(item) {
      confirm("Naozaj?") &&
        apiService.disapproveStaff(item).then((response) => {
          this.sync_staff = !this.sync_staff;
        });
    },

    getStaticPages() {
      apiService.getStaticPages().then((response) => {
        this.staticPages = response;
      });
    },

    updateStaticPage(item) {
      apiService.updateStaticPage(item);
    },

    editPayment(item) {
      this.dialog_payment = true;
      this.dialog_payment_item = Object.assign({}, item);
      this.dialog_payment_user_items = [];

      if (item.user_assigned_to) {
        this.dialog_payment_item_user = item.user_assigned_to.id;
        this.dialog_payment_user_items.push(item.user_assigned_to);
      }
    },

    updatePayment() {
      this.dialog_payment_item.user_assigned_to = this.dialog_payment_item_user;

      apiService.updateBankTransfer(this.dialog_payment_item).then(() => {
        this.sync_bank_transfers = !this.sync_bank_transfers;
        this.dialog_payment = false;
      });
    },

    newReferendum() {
      apiService.createReferendumAdmin({ "referendum_questions": [] }).then(() => {
        this.sync_referendum = !this.sync_referendum;
      })
    },

    newQuestionChoose() {
      apiService.createQuestionChooseAdmin({ "question_choose_questions": [] }).then(() => {
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    saveQuestionChooseItem(item) {
      for (let [index, value] of item.question_choose_questions.entries()) {
        value.order_question_choose = index;
      }

      apiService.updateQuestionChooseAdmin(item, item.id).then(() => {
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    removeQuestionFromQuestionChoose(id) {
      apiService.patchReferendumQuestionAdmin({ question_choose: null }, id).then(() => {
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    addQuestionToQuestionChoose(idQuestion, idQuestionChoose) {
      apiService.patchReferendumQuestionAdmin({ question_choose: idQuestionChoose }, idQuestion).then(() => {
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    saveReferendumItem(item) {
      for (let [index, value] of item.referendum_questions.entries()) {
        value.order_referendum = index;
      }

      apiService.updateReferendumAdmin(item, item.id).then(() => {
        this.sync_referendum = !this.sync_referendum;
      })
    },

    removeQuestionFromReferendum(id) {
      apiService.patchReferendumQuestionAdmin({ referendum: null }, id).then(() => {
        this.sync_referendum = !this.sync_referendum;
      })
    },

    addQuestionToReferendum(idQuestion, idReferendum) {
      apiService.patchReferendumQuestionAdmin({ referendum: idReferendum }, idQuestion).then(() => {
        this.sync_referendum = !this.sync_referendum;
      })
    },

    approveQuestion(item) {
      apiService.patchReferendumQuestionAdmin({ status: "APPROVED" }, item.id).then(() => {
        this.sync_referendum_questions = !this.sync_referendum_questions;
        this.sync_referendum = !this.sync_referendum;
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    disapproveQuestion(item) {
      apiService.patchReferendumQuestionAdmin({ status: "REJECTED" }, item.id).then(() => {
        this.sync_referendum_questions = !this.sync_referendum_questions;
        this.sync_referendum = !this.sync_referendum;
        this.sync_question_choose = !this.sync_question_choose;
      })
    },


    saveQuestionText(item) {
      apiService.patchReferendumQuestionAdmin({ text: item.text, tooltip: item.tooltip, enabled_discussion: item.enabled_discussion }, item.id).then(() => {
        this.sync_referendum_questions = !this.sync_referendum_questions;
        this.sync_referendum = !this.sync_referendum;
        this.sync_question_choose = !this.sync_question_choose;
      })
    },

    saveSurveyParty(item) {
      apiService.updateSurveyParty(item).then(() => {
        this.sync_survey = !this.sync_survey;
        this.sync_survey_parties = !this.sync_survey_parties;
      });
    },

    newSurveyParty() {
      apiService.createSurveyParty().then(() => {
        this.sync_survey_parties = !this.sync_survey_parties;
      });
    },

    newSurvey() {
      apiService.createSurvey().then(() => {
        this.sync_survey = !this.sync_survey;
      });
    },

    saveSurvey(item) {
      apiService.updateSurvey(item).then(() => {
        this.sync_survey = !this.sync_survey;
      });
    },

    addPartyToSurvey(survey_id, party_id) {
      apiService.addPartyToSurvey(survey_id, party_id).then(() => {
        this.sync_survey = !this.sync_survey;
      });
    },

    removePartyFromSurvey(survey_id, party_id) {
      apiService.removePartyFromSurvey(survey_id, party_id).then(() => {
        this.sync_survey = !this.sync_survey;
      });
    },

    saveUserPermissions(item) {
      let newItem = {};
      newItem.id = item.id;
      newItem.verified_by_superuser = item.verified_by_superuser;
      newItem.verified_by_superuser_uroven_0 = item.verified_by_superuser_uroven_0;
      newItem.verified_by_superuser_uroven_1 = item.verified_by_superuser_uroven_1;
      newItem.verified_by_superuser_uroven_2 = item.verified_by_superuser_uroven_2;
      newItem.verified_by_superuser_uroven_3 = item.verified_by_superuser_uroven_3;
      newItem.verified_by_superuser_uroven_4 = item.verified_by_superuser_uroven_4;


      apiService.patchAdminUser(newItem).then(() => {
        this.sync_users = !this.sync_users;
      });
    },

    resendUserSMS(item) {
      apiService.resendAdminUserSMS(item);
    },

    resendUserEmail(item) {
      apiService.resendAdminUserEmail(item);
    },

    downloadItem(url, name) {
      apiService.downloadItem(url, name);
    }
  },
};
</script>
