<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.poll_category"
          label="ID kategorie"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.name"
          label="Nazov"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.sub_name"
          label="Podnazov"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.order"
          label="Poradie"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
          v-model="item.url"
          label="URL (iba pre pevne polozky)"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        Popis
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <editor v-model="item.description"></editor>
      </v-col>
    </v-row>

    <v-row
      justify="space-between"
      dense
    >
      <v-col align="left">
        <v-switch
          label="Aktivne"
          v-model="item.active"
        ></v-switch>
      </v-col>

      <v-col align="left">
        <v-switch
          label="Hlasovanie povolene"
          v-model="item.enabled_voting"
        ></v-switch>
      </v-col>

      <!-- <v-col align="left">
        <v-color-picker
          mode="hexa"
          dot-size="25"
          swatches-max-height="200"
          v-model="item.color"
        ></v-color-picker>
      </v-col> -->

      <v-col align="right">
        <v-btn
          color="error"
          class="mr-10"
          @click="remove()"
        >Zmazat</v-btn>

        <v-btn
          color="primary"
          @click="save()"
        >Ulozit</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <general-poll-admins
          @sync-child="getItem()"
          :users="item.admins"
          :addAdminFunc="addAdminFunc"
          :removeAdminFunc="removeAdminFunc"
        >
        </general-poll-admins>
      </v-col>
    </v-row>

  </v-container>
</template>
    <script>
import { APIService } from "@/http/APIService.js";
import Editor from "@/components/Editor.vue"
import GeneralPollAdmins from "./GeneralPollAdmins.vue";

const apiService = new APIService();

export default {
  props: {
    itemID: { type: Number, required: true },
    admin: { type: Boolean, required: false, default: true },
    canAdmin: { type: Boolean, required: false, default: true },
  },


  components: {
    Editor,
    GeneralPollAdmins
  },

  data() {
    return {
      item: {}
    }
  },

  mounted() {
    this.getItem();
  },

  methods: {
    addAdminFunc(user_id) {
      return apiService.addAdminGeneralPoll(this.itemID, user_id);
    },

    removeAdminFunc(user_id) {
      return apiService.removeAdminGeneralPoll(this.itemID, user_id);
    },

    save() {
      apiService.updateGeneralPollAdmin(this.item).then(response => {
        this.$emit("sync-child");
        this.item = response;
      });
    },

    remove() {
      confirm("Naozaj zmazat?") && apiService.deleteGeneralPollAdmin(this.item.id).then(() => {
        this.$emit("sync-child");
      });
    },

    getItem() {
      apiService.getGeneralPollAdmin(this.itemID).then(response => {
        this.item = response;
      });
    },
  }
}
    </script>